export const UploadIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.4939 14.92L35.4845 14.8825L30.2252 1.51841C29.9908 0.763721 29.2923 0.243408 28.5002 0.243408H7.18141C6.38453 0.243408 5.67672 0.773096 5.45172 1.53716L0.534531 14.7653L0.520469 14.7981L0.511094 14.8356C0.450156 15.0653 0.431406 15.2997 0.464219 15.5293C0.459531 15.6043 0.454844 15.6793 0.454844 15.7543V32.9059C0.456083 33.6614 0.756748 34.3856 1.29096 34.9198C1.82517 35.454 2.54936 35.7547 3.30484 35.7559H32.7048C34.2752 35.7559 35.5548 34.4762 35.5595 32.9059V15.7543C35.5595 15.6934 35.5595 15.6325 35.5548 15.5809C35.5736 15.3512 35.5548 15.1309 35.4939 14.92ZM21.6283 12.9043L21.6142 13.6403C21.5767 15.745 20.1236 17.1606 18.0002 17.1606C16.9642 17.1606 16.0736 16.8278 15.4314 16.195C14.7892 15.5622 14.4377 14.6809 14.4189 13.6403L14.4048 12.9043H4.75797L8.48453 3.84341H27.197L31.0267 12.9043H21.6283ZM4.05016 16.5043H11.4236C12.5627 19.1809 14.9861 20.7606 18.0048 20.7606C19.5845 20.7606 21.0517 20.32 22.2377 19.4856C23.2783 18.7543 24.0892 17.7325 24.6142 16.5043H31.9502V32.1559H4.05016V16.5043Z"
        fill="#4ecc0a"
      />
    </svg>
  );
};
