import {
  Article,
  AssignmentInd,
  CreditCard,
  DirectionsBus,
  DirectionsCar,
  FolderShared,
  LocalBar,
} from '@mui/icons-material';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import BusinessIcon from '@mui/icons-material/Business';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Box, Card, CardContent, Grid, Link, Stack } from '@mui/material';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from 'src/components/loader';
import { MembersModal } from 'src/components/members-modal';
import ImageCropperModal from 'src/components/modal/ImageCropperModal';
import NavigateCard from 'src/components/navigate-card';
import { ProgramDetailModal } from 'src/components/program-detail-modal';
import { useLanguage, useProjectSettings } from 'src/hooks';
import { useCurrentProject, useProjectData, useProjectManagement } from 'src/hooks/project';
import useUserData from 'src/hooks/user/useUserData';
import {
  BadgeDot,
  ChangePasswordForm,
  ConfirmModal,
  MainLayout,
  PackageDetailModal,
  UserProject,
  ViewImageModal,
} from '../../components';
import BriefcaseIcon from '../../icons/briefcase';
import { joinProject } from '../../store/actions/app';
import Accreadition from '../accreadition';
import ArchivedOrderPage from '../archived-orders';
import BusinessProgramPage from '../business-program';
import BuyTicketPage from '../buy-ticket';
import MyOrderPage from '../my-order';
import UserSchedulePage from '../user-schedule';
import VizitsPage from '../vizits';
import CalendarPage from './components/CalendarPage';
import DistributeOrders from './components/DistributeOrders';
import ProjectInfoFiles from './components/InfoFiles';
import classes from './project.module.sass';
import PersonalLineIcon from 'src/icons/personal-line';
import { routes } from 'src/utils/routes';

const ProjectPage = () => {
  const navigate = useNavigate();
  const { detail: tabDetail, projectUuid = 'e30d7605-7376-b95d-de45-f1866d450298' } = useParams();

  const user = useSelector((state) => state.auth.user);

  const { lang, localizeMessage, localizeText, getLocalizationValue } = useLanguage();

  const { isLoading, data: project } = useCurrentProject({ projectUuid: projectUuid });
  const { isJoinedProject, confirmJoinProject } = useProjectManagement({ projectUuid: projectUuid, user: user });
  useProjectSettings({ projectUuid: projectUuid });
  const { projectDetailURL, websiteURL, mainImages } = useProjectData({ project: project });

  const PROJECT_DETAIL_TABS = useMemo(() => {
    return {
      ACCREDITATION: {
        key: 'accreadition',
        isCardLayout: true,
        isRequiredJoin: true,
        title: localizeText.ACCREDITATION,
        component: <Accreadition projectUuid={projectUuid} />,
      },
      BUY_TICKET: {
        key: 'buy-ticket',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.PURCHASE_SERVICE_PACKAGE,
        component: <BuyTicketPage />,
      },
      VIZITS: {
        key: 'vizits',
        isCardLayout: false,
        isRequiredJoin: false,
        title: localizeText.BUSINESS_CARD,
        component: <VizitsPage />,
      },
      PROGRAM: {
        key: 'business-program',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.BUSINESS_PROGRAM,
        component: <BusinessProgramPage />,
      },
      ORDERS: {
        key: 'orders',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.ORDERS,
        component: <MyOrderPage project={project} />,
      },
      ARCHIVED_ORDERS: {
        key: 'archived-orders',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.ARCHIVED_ORDERS,
        component: <ArchivedOrderPage />,
      },
      CALENDAR: {
        key: 'calendar',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.CALENDAR,
        component: <CalendarPage projectUuid={projectUuid} />,
      },
      FILES: {
        key: 'files',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.INFORMATION_FILES,
        component: <ProjectInfoFiles projectUuid={projectUuid} />,
      },
      DISTRIBUTE: {
        key: 'distribute',
        isCardLayout: true,
        isRequiredJoin: false,
        title: localizeText.DISTRIBUTE_ORDERS,
        component: <DistributeOrders project={project} projectUuid={projectUuid} />,
      },
      SCHEDULE: {
        key: 'schedule',
        isCardLayout: true,
        isRequiredJoin: false,
        title: `${localizeText.ARRIVAL}/${localizeText.DEPARTURE}`,
        component: <UserSchedulePage project={project} />,
      },
    };
  }, [project, projectUuid, localizeText]);

  const projectTabsByKey = keyBy(Object.values(PROJECT_DETAIL_TABS), 'key');
  const requiredJoinTabs = Object.values(PROJECT_DETAIL_TABS).reduce((arr, value) => {
    if (value.isRequiredJoin) {
      arr.push(value.key);
    }
    return arr;
  }, []);

  const handleGotoDetail = (detail) => () => {
    if (requiredJoinTabs.includes(detail) && !isJoinedProject) {
      confirmJoinProject({
        onSuccess: async () => await joinProject(projectUuid),
      });
    } else {
      navigate(`/projects/${detail}/${projectUuid}`);
    }
  };

  const GridItemCard = ({ children }) => (
    <Grid item xs={12} md={6} lg={4} sx={{ height: { xs: 100, md: 120 } }}>
      {children}
    </Grid>
  );

  const isBDKJoinedProject = (project) => {
    return (
      (project.modules.BDK && project.uuid !== 'faaa8088-f7be-780b-d8d5-56bd061c429c') ||
      (project.modules.BDK &&
        project.uuid === 'faaa8088-f7be-780b-d8d5-56bd061c429c' &&
        user?.projectSettings?.[project.uuid]?.mainRoleId === 'e44095a7-0af4-f25b-35f8-eb160f00e036')
    );
  };

  const renderCards = () => {
    return (
      <>
        {project.modules.accreditation && (
          <GridItemCard>
            <NavigateCard
              label={localizeText.ACCREDITATION}
              icon={<AssignmentInd />}
              extraElement={<BadgeDot style={{ position: 'absolute', top: '2.5rem', right: '3rem' }} />}
              onClick={handleGotoDetail(PROJECT_DETAIL_TABS.ACCREDITATION.key)}
            />
          </GridItemCard>
        )}
        {project.modules.carsAccreditation && (
          <GridItemCard>
            <NavigateCard label={localizeText.ACCREDITATION_VEHICLE} icon={<DirectionsCar />} />
          </GridItemCard>
        )}
        {project.modules.tickets && (
          <GridItemCard>
            <NavigateCard
              label={localizeText.PURCHASE_SERVICE_PACKAGE}
              icon={<CreditCard />}
              onClick={handleGotoDetail(PROJECT_DETAIL_TABS.BUY_TICKET.key)}
            />
          </GridItemCard>
        )}
        {project.modules.transfer && (
          <GridItemCard>
            <NavigateCard label={localizeText.YOU_HAVE_TRANSFER_SERVICE} icon={<DirectionsBus />} />
          </GridItemCard>
        )}
        {project.modules.bezviz && (
          <GridItemCard>
            <NavigateCard
              label={localizeText.VISA_FREE_ENTRY}
              icon={<FolderShared />}
              extraElement={<BadgeDot color="warning" style={{ position: 'absolute', top: '2.5rem', right: '3rem' }} />}
            />
          </GridItemCard>
        )}
        {project.modules.kultura && (
          <GridItemCard>
            <NavigateCard label={localizeText.CULTURAL_PROGRAM_AVAILABLE} icon={<LocalBar />} />
          </GridItemCard>
        )}
        {isJoinedProject && (
          <>
            <GridItemCard>
              <NavigateCard label={localizeText.ORDERS} icon={<AllInboxIcon />} onClick={handleGotoDetail('orders')} />
            </GridItemCard>
            {/* {project.modules.BDK && (
              <GridItemCard>
                <NavigateCard
                  label={localizeText.NETWORKING}
                  icon={<BriefcaseIcon />}
                  onClick={handleGotoDetail(PROJECT_DETAIL_TABS.VIZITS.key)}
                />
              </GridItemCard>
            )} */}
            {isBDKJoinedProject(project) && (
              <>
                <GridItemCard>
                  <NavigateCard
                    label={localizeText.NETWORKING}
                    icon={<BriefcaseIcon />}
                    onClick={handleGotoDetail(PROJECT_DETAIL_TABS.VIZITS.key)}
                  />
                </GridItemCard>
                <GridItemCard>
                  <NavigateCard
                    label={localizeText.BUSINESS_CARD}
                    icon={<PersonalLineIcon />}
                    onClick={() => {
                      navigate(`${routes.projectVizits.path.replace(':projectUuid', project.uuid)}?tab=business-card`);
                    }}
                  />
                </GridItemCard>
              </>
            )}
            <GridItemCard>
              <NavigateCard
                label={localizeText.BUSINESS_PROGRAM}
                icon={<BusinessIcon />}
                onClick={handleGotoDetail(PROJECT_DETAIL_TABS.PROGRAM.key)}
              />
            </GridItemCard>
            {project?.transfer && (
              <GridItemCard>
                <NavigateCard
                  label={`${localizeText.ARRIVAL}/${localizeText.DEPARTURE}`}
                  icon={<ChangeCircleOutlinedIcon />}
                  onClick={handleGotoDetail(PROJECT_DETAIL_TABS.SCHEDULE.key)}
                />
              </GridItemCard>
            )}

            <GridItemCard>
              <NavigateCard
                label={localizeText.INFORMATION_FILES}
                icon={<Article />}
                onClick={handleGotoDetail(PROJECT_DETAIL_TABS.FILES.key)}
              />
            </GridItemCard>
          </>
        )}
      </>
    );
  };

  const getProjectDetailBreadcrumbs = () => {
    const projectItem = {
      path: tabDetail ? projectDetailURL : null,
      title: getLocalizationValue(project, 'shortName') || project?.name,
    };
    const detailItem = tabDetail ? { path: null, title: projectTabsByKey?.[tabDetail]?.title } : null;
    return [{ path: '/', title: localizeText.MAIN_PAGE }, projectItem, detailItem].filter((item) => item);
  };
  const breadcrumbs = getProjectDetailBreadcrumbs();

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <main className={classes.root}>
        {isLoading ? (
          <Loader />
        ) : (
          <Stack gap="2rem" justifyContent="center">
            {project && <UserProject project={project} />}
            {tabDetail ? (
              project ? (
                projectTabsByKey[tabDetail].component
              ) : null
            ) : project ? (
              <>
                {/* <Grid container spacing={8} className={classes.statistic}>
                  {renderCards()}
                </Grid> */}
                <div className={classes.statisticContent}>
                  {/* <Grid container className={classes.statisticMobile}>
                    {renderCards()}
                  </Grid> */}
                  <Box
                    container
                    sx={{
                      marginTop: '-2rem',
                      marginBottom: '2rem',
                      boxShadow:
                        '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
                      backgroundColor: 'white',
                      padding: '10px',
                    }}
                  >
                    <h2 style={{ margin: '10px' }}>{localizeText.ABOUT_THE_EVENT}</h2>
                  </Box>
                  <div className={classes.desc}>
                    <div
                      style={{ whiteSpace: 'break-spaces' }}
                      dangerouslySetInnerHTML={{ __html: getLocalizationValue(project, 'comment') }}
                    />
                  </div>
                  {/* <Link href={websiteURL} target="_blank" rel="noreferrer">
                    {websiteURL}
                  </Link>
                  {mainImages.length > 1 && (
                    <div className={classes.photos}>
                      <ImageGallery items={mainImages} />
                    </div>
                  )} */}
                </div>
              </>
            ) : (
              <></>
            )}
          </Stack>
        )}
      </main>

      {/* Modals */}
      <ConfirmModal />
      <ViewImageModal />
      <PackageDetailModal />
      <ProgramDetailModal />
      <MembersModal />
      <ChangePasswordForm />
      <ImageCropperModal />
    </MainLayout>
  );
};

export default ProjectPage;
