import { ChevronLeft } from '@mui/icons-material';
import { Box, CardContent, Checkbox, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItem from 'src/components/order/OrderItem';
import { useAllowedNetworking, useLanguage } from 'src/hooks';
import { getMembersByProject } from 'src/store/actions/app';
import { routes } from 'src/utils/routes';
import { Button } from '../../components';
import { getMyOrder } from '../../store/actions/auth';
import Loader from 'src/components/loader';
import classes from './my-order.module.sass';

const MyOrderPage = ({ project }) => {
  const myOrders = useSelector((state) => state.auth.myOrders);
  const members = useSelector((state) => state.app.members);

  const navigate = useNavigate();
  const { projectUuid: id } = useParams();
  const { isAllowedNetworking } = useAllowedNetworking();
  const { localizeText } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    if (id) {
      Promise.all([getMembersByProject({ projectUuid: id }), getMyOrder(id)]).finally(() => setIsLoading(false));
    }
  }, [id]);

  const ableDistribute = members?.length > 0;

  const isPaidStatus = (order) => ['confirmed', 'approved', 'paid'].includes(order.status);

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <Box
          container
          sx={{
            marginTop: '-2rem',
            marginBottom: '2rem',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            width: '100%',
            padding: '20px',
          }}
        >
          <h2 className={classes.title}>{localizeText.ORDERS}</h2>
        </Box>
      </div>
      <Stack direction="row" gap="8px" justifyContent="end" marginBottom="28px">
        {/* {isAllowedNetworking && (
            <Button
              onClick={() => navigate(routes.projectVizits.path.replace(':projectUuid', id))}
              sx={{
                height: 'fit-content',
                borderRadius: '4px !important',
                textTransform: 'none',
              }}
            >
              {localizeText.START_NETWORKING}
            </Button>
          )} */}
        {/* {ableDistribute && selectedOrders.length > 0 && ( */}
        <Button
          disabled={selectedOrders.length < 1}
          onClick={() =>
            navigate(routes.projectDistribute.path.replace(':projectUuid', id), { state: { selectedOrders } })
          }
          sx={{
            height: 'fit-content',
            borderRadius: '4px !important',
            textTransform: 'none',
          }}
        >
          {localizeText.DISTRIBUTE_ORDERS}
          {selectedOrders.length > 0 ? ` (${selectedOrders.length})` : ''}
        </Button>
        {/* )} */}
        <Button
          onClick={() => navigate(routes.archivedOrders.path.replace(':projectUuid', id))}
          sx={{
            height: 'fit-content',
            borderRadius: '4px !important',
            textTransform: 'none',
          }}
        >
          {localizeText.ARCHIVED_ORDERS}
        </Button>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ background: 'transparent', borderRadius: '30px', marginTop: '16px' }}>
          {myOrders.length ? (
            <Stack alignContent="flex-start" gap="24px">
              {myOrders.map((order, index) => (
                <Stack key={index} direction={{ xs: 'column', md: 'row' }} gap="8px" alignItems="center">
                  {ableDistribute && (
                    <Box>
                      <Checkbox
                        checked={selectedOrders.includes(order.id)}
                        disabled={!isPaidStatus(order)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders([...selectedOrders, order.id]);
                          } else {
                            setSelectedOrders(selectedOrders.filter((orderId) => orderId !== order.id));
                          }
                        }}
                      />
                    </Box>
                  )}
                  <OrderItem order={order} project={project} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
              {localizeText.NO_DATA}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

export default MyOrderPage;
